<template>
   <div style="margin-top: 15px;">
        <v-col>
            <div class="box-curves">
				<v-row>
					<v-col cols="12" sm="10">
						<v-card-title class="text-h6 font-weight-black">Infrarouge</v-card-title>
					</v-col>
					<v-col cols="12" sm="2">
						<v-card-actions>
						<v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn>
					</v-card-actions>
					</v-col>
				</v-row>
        <line-chart :chartData="arrTemperature" :options="TemperatureChartOptions" :chartColors="turquoiseChartColors" :tag_id_name="tag_id_name" :pause="isPaused" />
        <br>
			</div>
    </v-col>
  </div>
</template>

<script>
import LineChart from "../components/LineChartXMultiple.js";
import {getDataLastId} from "../functions/get-data.js"

export default {
	props:["tag_id", "tag_id_name"],	
    components: {
			LineChart,
		},
    data() {
			return {
				id_memo : [],		
				date_memo : [],
				tps_memo : [],
				arrTemperature: [],
				val_i: 0,
				turquoiseChartColors: {
					borderColor: 'rgb(169, 65, 239)',
					backgroundColor: 'rgba(169, 65, 239, 0.2)'
				},
				isPaused: false,
                TemperatureChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 200,
								delay: 2000,
								onRefresh: (chart) => {
									for (let i = 0; i < this.tag_id.length; i++) {
										this.getCanIR(i).then((newData) => {
											let g = JSON.parse(JSON.stringify(newData));
											chart.data.datasets[i].data.push(...g);
										}).catch((e) => {
											console.log("[FETCH] Error");
										});
									}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
			};
		},
		methods: {
            async getCanIR(i) {
				const newData = []; // Déclarer le tableau à l'extérieur de la fonction
				try {
					const data = await getDataLastId(this.tag_id[i])
					// console.log(data)
						let newData = [];
						if (data[0].can_ir_led.length === 0) {

							newData.push({
								x: Date.now(),
							});
							return newData;
						}
						if (data[0].id <= this.id_memo[i]) {
							console.log('données pas captées')
							newData.push({
								x: Date.now(),
							});
						} else {
							if (this.date_memo[i] != null) {
								const date_now = new Date(data[0].received_on).getTime() - 2 * 60 * 60 * 1000;
								// const date_now =  Date.now();
								const datememo =  new Date(this.date_memo[i]).getTime() - 2 * 60 * 60 * 1000;
								const tpsmesurememo = this.tps_memo[i]

								console.log(date_now - (datememo + tpsmesurememo))

								const duration = data[0].tps_mesure;
								const frequency = duration / data[0].can_ir_led.length;
								// console.log(date_now, '    ', Date.now())
								// console.log(duration)
								// console.log(data[0].can_ir_led)

								for (let j = 0; j < data[0].can_ir_led.length; j++) {
									newData.push({
										y: data[0].can_ir_led[j],
										x: date_now + j * frequency,
									});
								}
							}

							this.date_memo[i] = data[0].received_on;
							this.tps_memo[i] = data[0].tps_mesure;

							return newData;
						}
						this.id_memo[i] = data[0].id;

				} catch (error) {
					console.error(error.message);
					throw new Error('Erreur lors de l\'appel à l\'API'); // Lever une erreur générique en cas d'erreur
				}
			},
			togglePause() {
				this.isPaused = !this.isPaused;
			},
		}
	};
			
</script>